<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 27.401 24.174"
  >
    <path
      :style="iconStyle"
      d="M16.658,3A11.92,11.92,0,0,0,4.914,15.087H1l5.076,5.224.091.188,5.271-5.412H7.524a9.263,9.263,0,0,1,9.134-9.4,9.263,9.263,0,0,1,9.134,9.4,9.263,9.263,0,0,1-9.134,9.4,8.934,8.934,0,0,1-6.446-2.767L8.359,23.628a11.52,11.52,0,0,0,8.3,3.545A11.92,11.92,0,0,0,28.4,15.087,11.92,11.92,0,0,0,16.658,3Zm-1.3,6.715V16.43l5.585,3.411.939-1.625L17.31,15.423V9.715Z"
      transform="translate(-1 -3)"
    />
  </svg>
</template>

<script>
export default {
  props: {
    height: {
      type: String,
      default: '24.174',
    },
    width: {
      type: String,
      default: '27.401',
    },
    color: {
      type: String,
      default: 'primary-green',
    },
  },
  computed: {
    /**
     * Icon Style
     * @param {void}
     * @returns {string} - Color
     * @description Assigns the specific color to the icon
     */
    iconStyle: function () {
      return {
        fill: ['$' + this.color, `var(--${this.color})`],
      }
    },
  },
}
</script>
